.addshop-slide-out-tab.addshop-modal.addshop-modaltab .addshop-button {
  padding: 0;
  float: none;
  margin-bottom: 0;
  background: none;
}


.addshop-slide-out-tab .addshop-copycode{
  margin-top:20px;
}
.addshop-slide-out-tab .addshop-copycodebtn.copied{
  background-color:#7fff7f;
}
.addshop-slide-out-tab .addshop-bottomclose{
  margin-top:50px;
  cursor:pointer;
}


.addshop-slide-out-tab.addshop-modal .addshop-cpncode {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  color: #0078e7;
  display: inline-block;
  position: relative;
}

.addshop-slide-out-tab.addshop-modal.addshop-modaltab {
  max-height: 500px;
  transform: translate(0%,50%);
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left {
  right: auto;
  left: -500px;
  transition: .2s;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left.addshop-show {
  left: 20px;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-right {
  left: auto;
  right: -500px;
  transition: .2s;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-right.addshop-show {
  right: 20px;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left.addshop-modaltab-bottom,
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-right.addshop-modaltab-bottom {
  bottom: 20px;
  transform: none;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab .addshop-modal-content-tab {
  width: 100%;
  height: 60px;
  color: #fff;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left .addshop-modal-content-tab {
left: 0px;
  transform: rotate(270deg);
  transform-origin: right top;
}
.addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-right .addshop-modal-content-tab {
  right: 560px;
  transform: rotate(-90deg);
  transform-origin: right top;
}

.addshop-slide-out-tab .addshop-h2.addshop-marginTop-0 {
  margin-top: 0;
}
@media only screen and (max-width: 480px) {
  .addshop-slide-out-tab.addshop-modal.addshop-modaltab {
      /*width: 90%;*/
  }
  .addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left, .addshop-modal.addshop-modaltab.addshop-modaltab-right {
      right: 10px;
      left: 10px;
      bottom: -400px;
      transform: none;
      margin: auto;
      max-height: 400px;
  }
  .addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left.addshop-show, .addshop-modal.addshop-modaltab.addshop-modaltab-right.addshop-show {
      bottom: 0;
      right: 10px;
      left: 10px;
  }
  .addshop-slide-out-tab.addshop-modal.addshop-modaltab.addshop-modaltab-left .addshop-modal-content-tab, .addshop-modal.addshop-modaltab.addshop-modaltab-right .addshop-modal-content-tab {
      top: -60px;
      left: 0;
      transform: none;
  }
}
