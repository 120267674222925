@import "variables.scss";
@import "mixins.scss";

.#{$class-prefix}wrap.#{$class-prefix}modal {
  position: fixed;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 530px;
  height: 600px;
  font-family: Arial,sans-serif;
  font-size: 16px;
  background: #fff;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  transition: .3s;
  -webkit-animation: addshopFadeIn 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: addshopFadeIn 1s; /* Firefox < 16 */
      -ms-animation: addshopFadeIn 1s; /* Internet Explorer */
       -o-animation: addshopFadeIn 1s; /* Opera < 12.1 */
          animation: addshopFadeIn 1s;
  &.#{$class-prefix}noanim {
    transition: 0;
    animation: none;
  }
  &.#{$class-prefix}modal-min {
    height: 180px;
    bottom: 20px;
    right: 20px;
    transform: translate(0%, 0%);
    max-width: 400px;
    cursor: pointer;
    &.#{$class-prefix}left {
      left: 20px;
      right: auto;
    }
    .#{$class-prefix}modal-wrap {
      overflow: hidden;
    }
  }
  &.#{$class-prefix}modalsquare {
    height: 550px;
    width: 530px;
  }
}

.#{$class-prefix}modal-content {
  justify-content: center;
  height: 100%;
  text-align: center;
}

.#{$class-prefix}block-sms {
	width: 100%;
	height: 100%;
	top: 0px;
	right: 0px;
	color: #F2F2FE;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	letter-spacing: -0.01em;
	text-transform: uppercase;
	text-align: -webkit-center;
}

.#{$class-prefix}zindex {
	z-index: 1;
	padding: 20px;
}

.#{$class-prefix}focus {
	font-weight: 800;
	font-size: 55px;
  line-height: 67px;
	letter-spacing: -0.02em;
}

.#{$class-prefix}text-small-sms {
	color: #F2F2FE;
	font-style: normal;
	text-transform: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: -0.01em;
	text-decoration: underline;
	cursor: pointer;
}

.#{$class-prefix}copy-btn-sms {
	padding: 15px;
	color: #FFFFFF;
	cursor: pointer;
	font-weight: bold;
	font-size: 16px;
	margin: 10px 0px 10px 0px;
	background: linear-gradient(180deg, #0001E2 0%, #6138FF 100%);
	border-radius: 5px;
	width: 60%;
	height: 58px;
}

.#{$class-prefix}copy-btn-sms-desktop {
  display: block;
}

.#{$class-prefix}copy-btn-sms-mobile {
  display: none;
}
.#{$class-prefix}close-btn-sms {
	padding: 15px;
	color: #FFFFFF;
	cursor: pointer;
	text-transform: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	margin: 10px 0px 10px 0px;
	background: linear-gradient(180deg, #0001E2 0%, #6138FF 100%);
	border-radius: 5px;
	width: 35%;
	height: 58px;
}

.#{$class-prefix}blank-wrap {
	background: #0000!important;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%)!important;
}

.#{$class-prefix}success-sms {
	font-size: 16px;
	line-height: 24px;
	width: 56%;
	text-align: center;
	text-transform: none;
}

.#{$class-prefix}offer-sms-modal {
	padding: 0px;
	background: linear-gradient(137.23deg, #282878 0%, #000008 96.4%);
	border-radius: 25px;
}

.#{$class-prefix}input-phone {
	text-align: center;
	background: #FCFCFC;
	border: 1px solid #5C617D;
	box-sizing: border-box;
	border-radius: 5px;
	color: #6138FF;
	width: 60%;
	height: 58px;
	margin-top: 30px;
	margin-bottom: 20px;
}

.#{$class-prefix}notice-agree {
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	color: #FCFCFC;
	opacity: 0.8;
	text-transform: none;
	width: 100%;
	text-align: left;
}

.#{$class-prefix}notice-cont {
	width: 60%;
	display: inline-flex;
}

.#{$class-prefix}checkbox {
	width: 10%;
}

.#{$class-prefix}block {
	width: 100%;
	height: 100%;
	top: 0px;
	right: 0px;

	color: #F2F2FE;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	letter-spacing: -0.01em;
	text-transform: uppercase;
	text-align: -webkit-center;
}

.#{$class-prefix}code-div {
  margin: 35px 0px 0px 0px;
  background: #f9f9ff;
	box-sizing: border-box;
	width: fit-content;
	display: inline-flex;
	border-radius: 5px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
}

.#{$class-prefix}color-b {
	color: #232356;
  background: #0001E2;
}

.#{$class-prefix}color-b:hover {
  color: #232356;
  background: linear-gradient(180deg,#6138ff,#0001e2);
}

.#{$class-prefix}text-small {
	color: #F2F2FE;
	font-style: normal;
	text-transform: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: -0.01em;
}

.addshop-btn-gradient { 
	background-image: linear-gradient(90deg, #0001E2, #0001E2);
  background-position:0 0,center;
  background-size:0% 100%,cover;
  background-repeat:no-repeat;
  transition: 0.5s; 
	width: 100%;
	height: 100%;
	padding: 17px 0px 0px 0px;
}

.addshop-btn-gradient-clicked { 
  background-image: linear-gradient(90deg, #0001E2, #0001E2);
  background-size:100% 100% ,cover;
}

.#{$class-prefix}copy-btn {
	color: #FFFFFF;
	cursor: pointer;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	margin: 10px 0px 10px 0px;
  background: #6138FF;
	background: linear-gradient(180deg, #0001E2 0%, #6138FF 100%);
	border-radius: 5px;
	width: 55%;
	height: 58px;
  display: inline-block;
}

.#{$class-prefix}copy-icon {
	border-radius: 0px 5px 5px 0px;
	cursor: pointer;
}

.#{$class-prefix}pd1 {
	padding: 10px;
  padding: 0px 20px 0px 20px;
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}wrap.#{$class-prefix}modal {
    width: min(100%, 530px);
    bottom: 0px;
    transform: translate(50%,0%);
  }

  .#{$class-prefix}copy-btn-sms-desktop {
    display: none;
  }
  
  .#{$class-prefix}copy-btn-sms-mobile {
    display: block;
    padding:0px;
  }

  .#{$class-prefix}modal-content.#{$class-prefix}text-center.#{$class-prefix}form {
    border-radius: 0px;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}wrap.#{$class-prefix}modal.#{$class-prefix}modal-min {
    width: calc(100% - 40px);
    height: 150px;
  }
}

.#{$class-prefix}modal-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  scrollbar-face-color: #fff;
  scrollbar-track-color: #aaa;
  .#{$class-prefix}modal-header {
    position: relative;
    background-color: #5b5b5b;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: $brand-white;
    transition: all .3s;
    -webkit-transition: all .3s;
    overflow: hidden;
    video {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  .#{$class-prefix}modal-content {
    padding: 30px;
  }
  .#{$class-prefix}coupon {
    display: inline-block;
    padding: 15px;
    border: 3px dashed #BAA8FF;
    border-right-width: 0px;
    border-radius: 5px 0px 0px 5px;
    background: #f9f9ff;
  }
  #AddShoppersWall {
    margin-top: -10px !important;
    background: 0 0 !important;
    text-align: left !important;
    .adw-block .adw-sharebox, .adw-signature {
      display: none !important;
    }
    #AddShoppersWallSettings {
      background: 0 0 !important;
    }
    #adw-price-range-ctn .adw-control .adw-control-wrapper {
      border: none !important;
    }
    .adw-block .adw-content {
      .adw-img {
        margin: 0 !important;
        max-width: 100% !important;
      }
      h2 a {
        text-align: left;
      }
    }
    #AddShoppersWallSettings {
      .adw-wish-list-profile {
        margin-bottom: 20px;
      }
      .adw-wish-list-image {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
      .adw-wish-list-name {
        font-size: 18px;
        margin-left: 0;
      }
      .adw-wish-list-share {
        top: 0;
      }
    }
  }
  #AddShoppersRefer {
    margin-top: -10px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .share-buttons {
    #share-buttons-footer, #widget-header-logo, .share-buttons-box-header, .sharing-link, .share-arrow, .share-cpn {
      display: none !important;
    }
    .share-button-wrapper {
      float: none !important;
      display: inline-block;
    }
    .share-via {
      float: none !important;
      max-width: 100% !important;
      margin-top: 8px !important;
      margin-bottom: 10px;
    }
    .submit-button {
      width: 100% !important;
    }
    #share-buttons-box {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      padding-bottom: 40px;
    }
    .share-buttons-product {
      width: 100% !important;
      padding: 0 !important;
      height: auto !important;
      overflow: hidden !important;
      .product-details {
        width: 100% !important;
        max-height: auto !important;
        margin: 0 !important;
        text-align: center !important;
        .product-name {
          text-align: center !important;
          color: #5b5b5b !important;
        }
      }
      .product-description {
        text-align: center !important;
        color: #5b5b5b !important;
      }
      .product-image, .product-price {
        display: inline-block !important;
        float: none !important;
      }
      #widget-header-logo {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        display: inline-block !important;
      }
    }
    .share-buttons-center {
      height: auto !important;
      overflow: hidden !important;
      padding: 20px 0 !important;
      border-top: 1px #ddd solid !important;
      border-bottom: 1px #ddd solid !important;
      margin-bottom: 20px;
    }
    .sharing-link-label {
      line-height: 35px !important;
    }
    .arrow-up {
      height: 10px !important;
      width: 10px !important;
      background: #FD7F54 !important;
      border-radius: 50% !important;
      margin: 5px auto 0 !important;
      border: none !important;
      right: 5px;
      position: relative;
    }
    #share-buttons-box-msg {
      padding: 0 !important;
      background: #fff !important;
    }
    #share-buttons-box-msg-textarea {
      width: 100% !important;
      border: 1px solid #ddd !important;
    }
    #twitter-counter {
      right: 10px !important;
      bottom: 60px !important;
    }
    .custom-submit-button {
      display: block !important;
    }
    .shbtn {
      bottom: auto !important;
    }
    #share-success-left {
      margin-right: 190px;
      width: auto;
      height: auto;
    }
    .custom-submit-button .primary-button {
      height: auto !important;
      padding: 20px !important;
      width: 100% !important;
      margin-top: 20px !important;
      box-sizing: border-box !important;
      font-size: 18px !important;
      box-shadow: none !important;
      line-height: normal !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .#{$class-prefix}modal-header {
    height: 150px;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .#{$class-prefix}modal-header .#{$class-prefix}modal-header-content {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .#{$class-prefix}modal-header .#{$class-prefix}modal-header-content span {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .#{$class-prefix}modal-content .#{$class-prefix}h3 {
    font-size: 22px;
    span {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .#{$class-prefix}modal-content .#{$class-prefix}h4 span {
    font-size: 18px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .#{$class-prefix}modal-wrap #AddShoppersWall {
    .adw-block:hover .adw-sharebox, div#adw-filter-toggle {
      display: none !important;
    }
    .adw-block:hover {
      -webkit-transform: translateY(0) !important;
      -ms-transform: translateY(0) !important;
      transform: translateY(0) !important;
      box-shadow: none !important;
    }
    #AddShoppersWallSettings .adw-filter.adw-open.adw-extra-filter {
      display: block !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons .share-button-wrapper {
    float: none !important;
    display: inline-block !important;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons .share-via {
    display: block !important;
    text-align: center !important;
    margin: 0 0 10px !important;
    float: none !important;
    max-width: none !important;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons .share-buttons-center {
    display: block !important;
    height: auto !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons .sharing-link {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons #twitter-counter {
    right: 10px !important;
    bottom: 120px !important;
  }
}

@media screen and (max-width: 480px) {
  .#{$class-prefix}modal-wrap .share-buttons #share-button-more {
    top: -3px;
  }
}

.#{$class-prefix}pinline {
  text-align: center;
  display: block;
  margin: 15px 0;
  overflow: hidden;
  span {
    position: relative;
    display: inline-block;
    line-height: 1;
    color: #ddd;
    font-size: 16px;
    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      height: 1px;
      background: #ddd;
      width: 99999px;
    }
    &:before {
      left: 100%;
      margin-left: 15px;
    }
    &:after {
      right: 100%;
      margin-right: 15px;
    }
  }
}

.#{$class-prefix}sociallogin {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  overflow: hidden;
  li {
    margin-bottom: 1%;
    width: 100%;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    line-height: 50px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    position: relative;
    cursor: pointer;
    &:nth-child(even) {
      margin-right: 0;
    }
    .#{$class-prefix}check {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 5px;
      left: 5px;
      background: url(https://s3.amazonaws.com/addshoppers-product/img/check.svg) center no-repeat rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: none;
    }
  }
  .#{$class-prefix}facebook {
    background-color: #4666bf;
  }
  .#{$class-prefix}twitter {
    background-color: #77C4FA;
  }
  .#{$class-prefix}google {
    background-color: #D75334;
  }
  .#{$class-prefix}amazon {
    background-color: #E1AA22;
  }
  .#{$class-prefix}instagram {
    background-color: #504F4F;
  }
  .#{$class-prefix}paypal {
    background-color: #4A7B9D;
  }
  .#{$class-prefix}linkedin {
    background-color: #007BB6;
  }
  .#{$class-prefix}facebook {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/fb.svg);
  }
  .#{$class-prefix}twitter {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/twit.svg);
  }
  .#{$class-prefix}google {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/goog.svg);
  }
  .#{$class-prefix}amazon {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/amazon.svg);
  }
  .#{$class-prefix}instagram {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/insta.svg);
  }
  .#{$class-prefix}paypal {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/paypal.svg);
  }
  .#{$class-prefix}linkedin {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/linked.svg);
  }
  .#{$class-prefix}sign-out {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    color: #5b5b5b;
    background-color: #ddd !important;
    text-align: center;
  }
}

.#{$class-prefix}socialloginhalf li {
  margin-right: 1%;
  width: 49.5%;
  float: left;
}

.#{$class-prefix}inlineloginwrap {
  margin-right: -10px;
  margin-left: -10px;
  .#{$class-prefix}icon-login {
    display: table;
    table-layout: fixed;
    border-spacing: 10px 0;
    li {
      display: table-cell;
      float: none;
    }
  }
}

.#{$class-prefix}icontext-login {
  li {
    text-indent: 50px;
    background-image: none;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.1);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .#{$class-prefix}facebook:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/fb.svg);
  }
  .#{$class-prefix}twitter:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/twit.svg);
  }
  .#{$class-prefix}google:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/goog.svg);
  }
  .#{$class-prefix}amazon:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/amazon.svg);
  }
  .#{$class-prefix}instagram:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/insta.svg);
  }
  .#{$class-prefix}paypal:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/paypal.svg);
  }
  .#{$class-prefix}linkedin:before {
    background-image: url(https://s3.amazonaws.com/addshoppers-product/img/linked.svg);
  }
  .#{$class-prefix}sign-out {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    color: #5b5b5b;
    background-color: #ddd !important;
    text-align: center;
    text-indent: 0;
    &:before {
      display: none;
    }
  }
}
