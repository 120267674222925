@import "variables.scss";
@import "mixins.scss";

.#{$class-prefix}wrap {
  margin: 0;
  padding: 0;
  border: 0;
  background: 0;
  width: auto;
  height: auto;
  font-family: inherit;
  font-size: inherit;
  vertical-align: baseline;
  border-radius: 0;
  min-height: 0;
  min-width: 0;
  float: none;
  position: static;
  box-shadow: none;
  text-shadow: none;
  line-height: normal;
  text-indent: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  * {
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    width: auto;
    height: auto;
    font-family: inherit;
    font-size: inherit;
    vertical-align: baseline;
    border-radius: 0;
    min-height: 0;
    min-width: 0;
    float: none;
    position: static;
    box-shadow: none;
    text-shadow: none;
    line-height: normal;
    text-indent: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  font-family: Arial,sans-serif;
  font-size: 16px;
  line-height: 1.428571429;
  color: #333333;
}

.#{$class-prefix}overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
  display: none;
  z-index: 9;
  &.#{$class-prefix}active {
    display: block;
  }
}

.#{$class-prefix}close {
  position: absolute;
  right: 5%;
  top: 5%;
  background: rgba(0, 0, 0, 0.0)!important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: $gray-base;
  font-size: 20px;
  outline: none;
  line-height: 1px;
  z-index: 9;
}
.#{$class-prefix}checkbox-lab {
  cursor: pointer;
  padding: 0px 10px 0px 0px;
}
.#{$class-prefix}checkbox-lab > span {
  color: #34495E;
}
.#{$class-prefix}checkbox-lab > input {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
 border: 1px solid #FCFCFC;
  border-radius: 2px;
  outline: none;
  transition-duration: 0.5s;
  background-color: #FCFCFC00;
  cursor: pointer;
}

.#{$class-prefix}checkbox-lab > input:checked {
  border: 1px solid #6138FF;
  background-color: rgba(118, 73, 255, 0.75);
}
.#{$class-prefix}checkbox-lab > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #FFFFFF;
  position: relative;
 top: -26px;

}
.#{$class-prefix}checkbox-lab > input:active {
  border: 2px solid #6138FF;
}

.#{$class-prefix}round {
  border-radius: 500px;
}

.#{$class-prefix}roundsquare {
  border-radius: 5px;
}

.#{$class-prefix}square {
  border-radius: 0;
}