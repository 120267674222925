.addshoppers_b_modal,
#wrapper[data-gjs-type="wrapper"] {
  font-family: 'Arial', sans-serif;
  color: #5b5b5b;
  margin: 0;
}

.addshop-os-block_content,
.addshop-os-block_content * {
  margin: 0;
  padding: 0;
  border: 0;
  background: 0;
  width: auto;
  height: auto;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-radius: 0;
  min-height: 0;
  min-width: 0;
  float: none;
  position: static;
  box-shadow: none;
  text-shadow: none;
  line-height: normal;
  text-indent: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.addshop-os-block_content {
  padding: 0 !important;
  overflow: auto !important;
}

.addshop-os-block_content .modals__wrapper {
  margin-top: 0 !important;
}

.addshop-os-block_content p {
  margin: 20px 0;
}

.addshop-os-block_content .modal__wrapper-active,
.addshop-os-block_content .modal__wrapper-active .modal__content-full,
.addshop-os-block_content,
.addshop-os-block_content .modal__header {
  transition: 0s !important;
}

.addshop-os-block_content .modal__wrapper {
  animation: none !important;
}

.addshop-os-block_content .modal__wrapper {
  margin-bottom: 0 !important;
}

.addshop-os-block_content .modal__wrapper-deactive .modal__content-full {
  padding: 0 !important;
}


img.addshop-os-highlight {
  border: 3px solid #fd7f54;
  box-sizing: border-box;
}

.addshop- {
  &highlight {
    box-shadow: inset 0 0 0 3px #fd7f54;
  }

  &placeholder {
    padding: 40px 20%;
    background: #f2f2f2;
    border-radius: 5px;
    font-weight: bold;
  }

  &placeholder .config-btn {
    padding: 15px 20px;
    color: #fff;
    background: #5b5b5b;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
  }

  &vidwrap {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: auto !important;
  }

  &vidwrap img {
    width: 100%;
    height: auto;
  }

  // &button {
    // background-color: #64b3df;
    // padding: 20px;
    // color: #ffffff;
    // font-weight: bold;
  // }

  &modal-alert {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 50px;
    color: #fff;
    background: #e94a5e;
    text-align: center;
    font-size: 12px;
    z-index: 1;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  &stretch {
    width: 100%;
    height: 100%;
  }

  /*
    7/22/19 note: Previously, form elements with class names like
    "addshop-input" or "addshop-button" had styles that were being
    overwritten by CSS injected from the portal code.
    For standalone onsite campaigns that are not related to the portal
    (such as modals or tabs) and have descendant elements previously classed
    "addshop-input" etc, they are now "addstrap-os-input" or "addstrap-os-button", etc.
    ("os" standing for "onsite")
  */
  &os-form-group {
    margin-bottom: 15px;
  }

  &os-input,
  &os-textarea {
    padding: 20px;
    width: 100%;
    border: 1px solid #e7edf3;
  }

  &os-button {
    padding: 20px;
    color: #ffffff;
    font-weight: bold;
    background: #1F2D3D;
  }
}

.addshop-form-control {
  width: 100%;
  height: 54px;
  padding: 15px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.addshop-btn-block .addshop-button {
  width: 100%;
  padding: 15px 25px;
  line-height: 1.42857;
}
button.addshop_button_1 {
  color: #FFFFFF;
  border-radius: 0px;
  font-family: inherit;
  background-color: #00a3bb;
}
.addshop-wrap.addshop-modal button.addshop-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0,0,0,.8);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #5A6175;
  outline: none;
  line-height: 1px;
  z-index: 9;
}

@media only screen and (max-width: 480px) {
  .addshop-os-block_content .modal__wrapper .modal__header .modal__header-content,
  .addshop-os-block_content .modal__wrapper .modal__header .modal__header-content span {
    font-size: 23px !important;
  }
  .addshop-wrap.addshop-modal button.addshop-close{
    top: 3%;
    right: 16%;
  }
  .addshop-os- {
    &modal {
      font-size: 14px !important;
    }

    &heading1,
    &heading1 span {
      font-size: 22px !important;
    }

    &heading2,
    &heading2 span {
      font-size: 16px !important;
    }

    &form input[type='text'],
    &button {
      font-size: 16px !important;
    }
  }
}
